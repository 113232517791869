/*--------------------------------------------------------------
# Image Row
--------------------------------------------------------------*/
section.image-row {

    padding: 20px 0;

    .img-fluid {
        margin: 0 auto;
    }

    .align-items-center {
        justify-content: center;
    }

    .image-info-container {
        opacity: 0;
        position: absolute;
        left: 15px;
        bottom: 0;
        z-index: 3;
        right: 15px;
        // transition: all 0.3s;
        background: rgba(55, 81, 126, 0.8);
        padding: 10px 15px;

        h4 {
            font-size: 18px;
            color: #fff;
            font-weight: 600;
            margin-bottom: 0;
        }

        p {
            color: #f9fcfe;
            font-size: 14px;
            margin-bottom: 0;
        }

        .bx-plus {
            float: right;
            font-size: 24px;
            color: #fff;
            // transition: 0.3s;
        }

    }

}

.image-row:hover .image-info-container {
    opacity: 1;
}

