/*--------------------------------------------------------------
# Two Columns
--------------------------------------------------------------*/
section.two-columns .content {

    h3 {
        font-weight: 600;
        font-size: 26px;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            padding-left: 28px;
            position: relative;
        }

        li + li {
            margin-top: 10px;
        }

        i {
            position: absolute;
            left: 0;
            top: 2px;
            font-size: 20px;
            color: $blueLight;
            line-height: 1;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }

    .btn-learn-more {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 12px 32px;
        border-radius: 4px;
        // transition: 0.3s;
        line-height: 1;
        color: $blueLight;
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
        margin-top: 6px;
        border: 2px solid $blueLight;
    }

    .btn-learn-more:hover {
        background: $blueLight;
        color: #fff;
        text-decoration: none;
    }

}
