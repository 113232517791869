/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {

    padding-top: 0;
    border-right: 1px solid #d2d2d2;
    @media (max-width: 767px) {
        border-right: none;
    }

    .sidebar-root-entry-title a {
        font-size: 40px;
        font-weight: bold;
        text-transform: uppercase;
        color: $blueDark;
    }

    ul {
        padding-left: 0;
        padding-top: 20px;

        li {
            list-style: none;
            margin-left: 0;
            padding-left: 10px;
            padding-bottom: 10px;

            &::before {
                content: "-";
                padding-right: 6px;
                float: left;
            }

            h2 {
                font-size: 18px;
                font-weight: 500;
                font-family: $normalFont;
                margin-bottom: 0;

                a {
                    color: $darkText;
                }

            }

        }

    }

}
