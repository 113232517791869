/*--------------------------------------------------------------
# Graph With Side Images
--------------------------------------------------------------*/
.graph-side-image {

    .content {

        h3 {
            font-weight: 700;
            font-size: 32px;
            color: $blueDark;
            font-family: "Poppins", sans-serif;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                padding-bottom: 10px;
            }

            i {
                font-size: 20px;
                padding-right: 4px;
                color: $blueLight;
            }

            p:last-child {
                margin-bottom: 0;
            }

        }
    }

    .progress {
        height: 50px;
        display: block;
        background: none;

        .data-item {
            padding: 0;
            margin: 0 0 6px 0;
            text-transform: uppercase;
            display: block;
            font-weight: 600;
            font-family: "Poppins", sans-serif;
            color: $blueDark;

            .val {
                float: right;
                font-style: normal;
            }

        }

    }

    .progress-bar-wrap {
        background: #e8edf5;
    }

    .progress-bar {
        width: 1px;
        height: 10px;
        // transition: .9s;
        background-color: #4668a2;
    }

}
