/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    font-size: 14px;
    background: $blueDark;

    .footer-top {
        padding: 60px 0 30px 0;
        background: #fff;

        .container.top-border {
            border-top: 1px solid #ddd;
            padding-top: 40px;
        }

        .footer-contact {
            margin-bottom: 30px;

            h3 {
                font-size: 28px;
                margin: 0 0 10px 0;
                padding: 2px 0 2px 0;
                line-height: 1;
                color: $blueDark;
            }

            p {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
                font-family: $styledFont;
                color: #5e5e5e;
            }

        }

        h4 {
            font-size: 16px;
            font-weight: bold;
            color: $blueDark;
            position: relative;
            padding-bottom: 12px;
        }

        .footer-links {
            margin-bottom: 30px;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                i {
                    padding-right: 2px;
                    color: $blueLight;
                    font-size: 18px;
                    line-height: 1;
                }

                li {
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                }

                li:first-child {
                    padding-top: 0;
                }

                a {
                    color: #777777;
                    // transition: 0.3s;
                    display: inline-block;
                    line-height: 1;
                }

                a:hover {
                    text-decoration: none;
                    color: $blueLight;
                }

            }

        }

        .social-links {

            a {
                font-size: 18px;
                display: inline-block;
                background: $blueLight;
                color: #fff;
                line-height: 1;
                padding: 8px 0;
                margin-left: 4px;
                border-radius: 50%;
                text-align: center;
                width: 36px;
                height: 36px;
                // transition: 0.3s;
            }

            a:nth-child(1) {
                margin-left: 0;
            }

            a:hover {
                background: #209dd8;
                color: #fff;
                text-decoration: none;
            }

        }

    }

    .footer-bottom {
        padding-top: 30px;
        padding-bottom: 30px;
        color: #fff;
    }

    .copyright {
        float: left;
    }

    .credits {
        float: right;
        font-size: 13px;

        a {
            // transition: 0.3s;
        }

    }

}

@media (max-width: 768px) {
    #footer .footer-bottom {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    #footer .copyright, #footer .credits {
        text-align: center;
        float: none;
    }
    #footer .credits {
        padding-top: 4px;
    }
}
