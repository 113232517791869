/*--------------------------------------------------------------
# Newsletter
--------------------------------------------------------------*/
.block-newsletter {
    padding: 50px 0;
    background: #f3f5fa;
    text-align: center;
    font-size: 15px;
    color: $darkText;

    h4 {
        font-size: 24px;
        margin: 0 0 20px 0;
        padding: 0;
        line-height: 1;
        font-weight: 600;
        color: $blueDark;
    }

    form {
        margin-top: 30px;
        background: #fff;
        padding: 6px 10px;
        position: relative;
        border-radius: 50px;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
        text-align: left;

        label {
            display: none;
        }

        input[type="email"] {
            border: 0;
            padding: 4px 8px;
            width: calc(100% - 120px);
            margin-left: 10px;
        }

        input[type="submit"] {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            border: 0;
            background: none;
            font-size: 16px;
            padding: 0 20px;
            background: $blueLight;
            color: #fff;
            // transition: 0.3s;
            border-radius: 50px;
            box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
        }

        input[type="submit"]:hover {
            background: #209dd8;
        }

    }

}
