/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
html body {
    font-family: $normalFont;
    color: $darkText;
    background-attachment: fixed;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    a {
        color: $blueLight;
    }

    a:hover {
        color: $blueLightHover;
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: $styledFont;
    }

    .text-black {
        color: #000000;
    }

    #main {
        margin-top: 72px;
        min-height: 400px;
    }

    #main > :first-child {
        padding-top: 70px;
    }

    .cc-window.cc-floating {
        max-width: 22em;
        bottom: 4em !important;
    }

    .cc-revoke {
        transform: rotate(90deg) !important;
        left: -38px !important;
        right: auto;
        bottom: 6em !important;
    }

    blockquote {
        padding: 1% 4%;
        border-left: 6px solid #eee;
        font-style: italic;
    }

}

/*--------------------------------------------------------------
# Pager
--------------------------------------------------------------*/
.pagination {

    ul {
        list-style: none;
        margin: 20px auto;

        li {
            display: inline-block;
            margin: 0 10px;

            a i.bx {
                position: relative;
                top: 2px;
            }

        }

    }

}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: $blueDark;
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid $blueDark;
    border-top-color: #fff;
    border-bottom-color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    display: none;
    right: 15px;
    bottom: 100px;
    z-index: 99999;
}

.back-to-top i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: $blueLight;
    color: #fff;
    // transition: all 0.4s;
}

.back-to-top i:hover {
    background: #209dd8;
    color: #fff;
}

@media (max-width: 640px) {

    html body {

        #main {
            margin-top: 130px;
        }

    }

    .back-to-top {
        bottom: 240px;
    }

}

@media (max-width: 720px) {

    html body {

        #footer {

            .footer-bottom {
                min-height: 105px;
            }

        }

        .grecaptcha-badge {
            bottom: 60px !important;
        }

    }

}
