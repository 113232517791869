/*--------------------------------------------------------------
# Text With Side Image
--------------------------------------------------------------*/
.text-with-side-image {

    .image-reverse {
        flex-direction: row-reverse;
    }

}
