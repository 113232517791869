/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
section.hero {

    width: 100%;
    height: auto;

    .container {

        .hero-reverse {
            flex-direction: row-reverse;
        }

    }

    h2 {
        margin: 0 0 10px 0;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        color: #fff;
    }

    h3 {
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 50px;
        font-size: 24px;
    }

    .btn-get-started {
        font-family: $styledFont;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 10px 20px 11px;
        border-radius: 20px;
        // transition: 0.5s;
        margin: 10px 0 0 0;
        color: #fff;
        background: $blueLight;
    }

    .btn-get-started:hover {
        background: #209dd8;
    }

    .btn-watch-video {
        font-size: 16px;
        display: inline-block;
        padding: 10px 30px;
        // transition: 0.5s;
        margin: 10px 0 0 25px;
        color: #fff;
        position: relative;
        border: 1px solid #fff;
        border-radius: 50px;
    }

    .btn-watch-video i {
        color: #fff;
        font-size: 32px;
        position: absolute;
        left: 0;
        top: 7px;
        // transition: 0.3s;
    }

    .btn-watch-video:hover i {
        color: $blueLight;
    }

    .animated {
        animation: up-down 2s ease-in-out infinite alternate-reverse both;
        max-height: 550px;
    }

    .hero-img {
        text-align: center;
    }

    @media (max-width: 991px) {

        text-align: center;

        .animated {
            -webkit-animation: none;
            animation: none;
        }

    }

    @media (max-width: 768px) {

        h2 {
            font-size: 28px;
            line-height: 36px;
        }

        h3 {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 30px;
        }

    }

    @media (max-width: 640px) {

        .btn-get-started {
            font-size: 16px;
            padding: 10px 24px 11px 24px;
            margin-bottom: 20px;
        }

        .btn-watch-video {
            font-size: 16px;
            margin: 10px auto;
        }

        .btn-watch-video i {
            font-size: 32px;
            top: 7px;
        }

    }

}

.hero.height-small {
    height: 40vh;
}

.hero.height-small-with-margin {
    height: 40vh;
    min-height: 520px;
    margin: 8vh auto;
}

@-webkit-keyframes up-down {

    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }

}

@keyframes up-down {

    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }

}
