/*--------------------------------------------------------------
# Squares Grid
--------------------------------------------------------------*/
.squares-grid {

    .square-item {
        margin-top: 20px;
    }

    .icon-box {
        width: 100%;
        padding: 50px 30px;
        background: #fff;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
        // transition: all ease-in-out 0.4s;

        .icon {
            min-height: 200px;
            margin-bottom: 30px;
            text-align: center;

            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 200px;
            }

            i {
                color: $blueLight;
                font-size: 36px;
                // transition: 0.3s;
            }

        }

        h4 {
            font-weight: 500;
            margin-bottom: 0;
            font-size: 24px;

            a {
                color: $blueDark;
                // transition: ease-in-out 0.3s;
            }

        }

        p {
            line-height: 24px;
            font-size: 14px;
            margin-top: 15px;
        }

    }

    .icon-box:hover {
        // transform: translateY(-10px);
    }

    .icon-box:hover h4 a {
        color: $blueLight;
    }

}
