/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url("../img/cta-bg.jpg") fixed center center;
    background-size: cover;
    padding: 120px 0;

    h3 {
        color: #fff;
        font-size: 28px;
        font-weight: 700;
    }

    p {
        color: #fff;
    }

    .cta-btn {
        font-family: $styledFont;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 12px 40px;
        border-radius: 50px;
        // transition: 0.5s;
        margin: 10px;
        border: 2px solid #fff;
        color: #fff;

        &:hover {
            background: $blueLight;
            border: 2px solid $blueLight;
        }

    }

}

@media (max-width: 1024px) {

    .cta {
        background-attachment: scroll;
    }

}

@media (min-width: 769px) {

    .cta .cta-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

}
