/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
    // transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    background-color: $blueDark;

    .logo {
        font-size: 40px;
        margin: 0;
        padding: 0;
        line-height: 1;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;

        a {
            color: #fff;
        }

        img {
            max-height: 40px;
        }

    }

    .logo.reldens-logo a {
        font-family: $reldensFont;
        font-weight: bold;
        color: $reldensFontColorBlue;
    }

    form.search {
        background: rgba(255, 255, 255, 0.2);
        padding: 6px 10px;
        position: relative;
        border-radius: 50px;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.06);
        text-align: left;
        margin-left: 30px;
        min-width: 40px;
        min-height: 40px;

        .quicksearch {
            background: transparent;
            border: none;
            color: #ffffff;
            padding: 2px 8px;

            &::placeholder{
                color: #ffffff;
            }

            &:focus {
                outline: none;
            }

        }

        button[type="submit"]{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            border: 0;
            background: none;
            font-size: 20px;
            padding: 0 10px;
            background: #47b2e4;
            color: #fff;
            // transition: 0.3s;
            border-radius: 50px;
            box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
            cursor: pointer;
        }

    }

    .mobile-search {
        display: none;
    }

}

.no-results {
    text-align: center;
}

#header.header-scrolled, #header.header-inner-pages {
    background: rgba(40, 58, 90, 0.9);
}

@media (max-width: 640px) {

    #header {

        form.search {
            display: none;
        }

        .mobile-search {
            display: block;

            form.search {
                display: block;
                width: 90%;
                margin: 20px 5% 0;
                padding: 5px 10px;
            }

        }

    }

}
