/*--------------------------------------------------------------
# Tiled Map Optimizer
--------------------------------------------------------------*/
.tiled-map-optimizer {

    padding-top: 0;

    .tiled-map-optimizer-result a {
        margin: 0 auto;
    }

    .tiled-maps-container, .json-container {

        input[type="file"] {
            height: auto;
            padding: 8px;
            margin: 0 0 6px;
            border: 1px solid #ddd;
        }

    }

}
