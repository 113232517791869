/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
    padding: 0 100px;
}

.faq .faq-list ul {
    padding: 0;
    list-style: none;
}

.faq .faq-list li + li {
    margin-top: 15px;
}

.faq .faq-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
}

.faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
}

.faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: $blueLight;
}

.faq .faq-list .icon-show, .faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
}

.faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
    display: none;
}

.faq .faq-list a.collapsed {
    color: $blueDark;
    // transition: 0.3s;
}

.faq .faq-list a.collapsed:hover {
    color: $blueLight;
}

.faq .faq-list a.collapsed .icon-show {
    display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
    display: none;
}

@media (max-width: 1200px) {
    .faq .faq-list {
        padding: 0;
    }
}
