/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

section.section-title-container {
    padding-bottom: 0;
}

section.small-padding {
    padding: 20px 0;
}

.section-bg {
    background-color: #f3f5fa;
}

.section-title {
    text-align: center;
    padding-bottom: 30px;

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 32px;
    }

    h1, h2 {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        color: $blueDark;
    }

    h1::before, h2::before {
        content: '';
        position: absolute;
        display: block;
        width: 120px;
        height: 1px;
        background: #ddd;
        bottom: 1px;
        left: calc(50% - 60px);
    }

    h1::after, h2::after {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        background: $blueLight;
        bottom: 0;
        left: calc(50% - 20px);
    }

}

.section-title p {
    margin-bottom: 0;
}

.video-content {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}
