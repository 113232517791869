/*--------------------------------------------------------------
# Articles Grid
--------------------------------------------------------------*/
.block-articles-grid {

    .entry-item {
        position: relative;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
        padding: 30px;
        border-radius: 5px;
        background: #fff;
        // transition: 0.5s;

        .pic {
            overflow: hidden;
            width: 120px;

            img {
                // transition: ease-in-out 0.3s;
            }

        }

        .pic.use-circle {
            border-radius: 50%;
        }

        .entry-info {
            padding-left: 30px;
            min-height: 180px;
            max-width: 370px;
        }

        h4 {
            font-weight: 700;
            margin-bottom: 5px;
            font-size: 20px;
            color: $blueDark;
        }

        span {
            display: block;
            font-size: 15px;
            padding-bottom: 10px;
            position: relative;
            font-weight: 500;
            p {
                color: $darkText;
            }
        }

        span::after {
            content: '';
            position: absolute;
            display: block;
            width: 50px;
            height: 1px;
            background: #cbd6e9;
            bottom: 0;
            left: 0;
        }

        p {
            margin: 10px 0 0 0;
            font-size: 14px;
        }


        .social {
            margin-top: 12px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            a {
                // transition: ease-in-out 0.3s;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                width: 32px;
                height: 32px;
                background: #eff2f8;

                i {
                    color: $blueDark;
                    font-size: 16px;
                    margin: 0 2px;
                }

            }

            a:hover {
                background: $blueLight;

                i {
                    color: #fff;
                }

            }

        }

        .social a + a {
            margin-left: 8px;
        }

    }

    .entry-item:hover {
        transform: translateY(-10px);
    }

}
