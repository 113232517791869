/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu > ul {
    display: flex;
}

.nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
    padding: 10px 0 10px 28px;
}

.nav-menu {

    a {
        display: block;
        position: relative;
        color: #fff;
        // transition: 0.3s;
        font-size: 15px;
        letter-spacing: 0.5px;
        font-weight: 500;
        font-family: $normalFont;
    }

    h2 {
        font-size: 15px;
        letter-spacing: 0.5px;
        font-weight: 500;
        font-family: $normalFont;
        margin-bottom: 0;
    }

}

.nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
    color: $blueLight;
}

.nav-menu .drop-down ul {
    border-radius: 8px;
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    // transition: 0.3s;
}

.nav-menu .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
}

.nav-menu .drop-down ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #0c3c53;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
    color: $blueLight;
}

.nav-menu .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
    top: 0;
    left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
}

.nav-menu .drop-down .drop-down > a {
    padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
    content: "\eaa0";
    font-family: IcoFont;
    position: absolute;
    right: 15px;
}

@media (max-width: 1366px) {
    .nav-menu .drop-down .drop-down ul {
        left: -90%;
    }
    .nav-menu .drop-down .drop-down:hover > ul {
        left: -100%;
    }
    .nav-menu .drop-down .drop-down > a:after {
        content: "\ea9d";
    }
}

/* Get Startet Button */
.get-started-btn {
    margin-left: 25px;
    color: #fff;
    border-radius: 20px;
    padding: 6px 25px 7px 25px;
    white-space: nowrap;
    // transition: 0.3s;
    font-size: 14px;
    display: inline-block;
    border: 2px solid $blueLight;
    font-weight: 600;
}

.get-started-btn:hover {
    background: #31a9e1;
    color: #fff;
}

@media (max-width: 768px) {
    .get-started-btn {
        margin: 0 48px 0 0;
        padding: 6px 20px 7px 20px;
    }
}

/* Mobile Navigation */
.mobile-nav-toggle {
    position: fixed;
    top: 20px;
    right: 15px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    // transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
}

.mobile-nav-toggle i {
    color: #fff;
}

.mobile-nav {
    position: fixed;
    top: 55px;
    right: 15px;
    z-index: 9999;
    overflow-y: auto;
    background: #fff;
    // transition: ease-in-out 0.2s;
    opacity: 0;
    visibility: hidden;
    border-radius: 4px;
    padding: 10px 0;
    text-align: right;
    min-width: 200px;
}

.mobile-nav * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.mobile-nav a {
    display: block;
    position: relative;
    color: $blueDark;
    padding: 10px 20px;
    font-weight: 500;
    outline: none;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
    color: $blueLight;
    text-decoration: none;
}

.mobile-nav .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont Verdana sans-serif;
    padding-left: 10px;
    position: absolute;
    right: 15px;
}

.mobile-nav .active.drop-down > a:after {
    content: "\eaa1";
}

.mobile-nav .drop-down > a {
    padding-right: 35px;
}

.mobile-nav .drop-down {
    ul {
        display: none;
        overflow: hidden;
    }

    &.active {
        ul {
            display: block;
        }
    }
}

.mobile-nav .drop-down li {
    padding-left: 20px;
}

.mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(40, 58, 90, 0.6);
    overflow: hidden;
    display: none;
    // transition: ease-in-out 0.2s;
}

.mobile-nav-active {
    overflow: hidden;
}

.mobile-nav-active .mobile-nav {
    opacity: 1;
    visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
    color: #fff;
}
