/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;
}

.portfolio-details .portfolio-details-container {
    position: relative;
}

.portfolio-details .portfolio-details-carousel {
    position: relative;
    z-index: 1;
}

.portfolio-details .portfolio-details-carousel .owl-nav, .portfolio-details .portfolio-details-carousel .owl-dots {
    margin-top: 5px;
    text-align: left;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
    display: inline-block;
    margin: 0 10px 0 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
    background-color: $blueLight !important;
}

.portfolio-details .portfolio-info {
    padding: 30px;
    position: absolute;
    right: 0;
    bottom: -70px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
    margin-top: 10px;
}

.portfolio-details .portfolio-description {
    padding-top: 50px;
}

.portfolio-details .portfolio-description h2 {
    width: 50%;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
    padding: 0 0 0 0;
}

@media (max-width: 768px) {
    .portfolio-details .portfolio-description h2 {
        width: 100%;
    }
    .portfolio-details .portfolio-info {
        position: static;
        margin-top: 30px;
    }
}
