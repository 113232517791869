/*--------------------------------------------------------------
# Rich Text
--------------------------------------------------------------*/
section.block-rich-text {
    padding: 20px 0;

    pre {
        background-color: #e5e5e5;
        padding: 20px;
        white-space: pre-wrap;
    }

    table {
        width: 100%;
        font-size: 14px;

        td {
            padding: 10px 20px;
            vertical-align: top;
            border: 1px solid #dfdfdf;
            text-align: left;
            word-break: break-word;
        }

    }

}
