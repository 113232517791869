/*--------------------------------------------------------------
# Site Specifics - Reldens
--------------------------------------------------------------*/
.reldens {

    #header {
        background-color: #000;
        border-bottom: 2px solid #ffffff;
    }

    #footer {

        background-color: #000000;

        .footer-top {

            .footer-contact {

                h3 {
                    text-transform: lowercase;
                    font-weight: bold;
                    font-family: $reldensFont;
                }

            }

        }

    }

    @media (max-width: 991px) {

        section.hero {
            height: auto;
        }

    }

}
