/*--------------------------------------------------------------
# Definitions
--------------------------------------------------------------*/
$blueDark: #37517e;
$blueLight: #47b2e4;
$blueLightHover: #73c5eb;
$darkText: #444444;
$reldensFontColorBlue: #2f7dde;
$normalFont: "Open Sans", sans-serif;
$styledFont: "Jost", sans-serif;
$reldensFont: "Play", sans-serif;
