/*--------------------------------------------------------------
# Accordion List
--------------------------------------------------------------*/
.block-accordion-list {

    .content {
        padding: 60px 100px 0 100px;

        h3 {
            font-weight: 400;
            font-size: 34px;
            color: $blueDark;
        }

        h4 {
            font-size: 20px;
            font-weight: 700;
            margin-top: 5px;
        }

        p {
            font-size: 15px;
            color: #848484;
        }
    }

    .img {
        background-size: auto;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .accordion-list {
        padding: 0 100px 60px 100px;

        ul {
            padding: 0;
            list-style: none;
        }

        li + li {
            margin-top: 15px;
        }

        li {
            padding: 20px;
            background: #fff;
            border-radius: 4px;
        }

        a {
            display: block;
            position: relative;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            padding-right: 30px;
            outline: none;
        }

        span {
            color: $blueLight;
            font-weight: 600;
            font-size: 18px;
            padding-right: 10px;
        }

        i {
            font-size: 24px;
            position: absolute;
            right: 0;
            top: 0;
        }

        p {
            margin-bottom: 0;
            padding: 10px 0 0 0;
        }

        .icon-show {
            display: none;
        }

        a.collapsed {
            color: #343a40;
        }

        a.collapsed:hover {
            color: $blueLight;
        }

        a.collapsed .icon-show {
            display: inline-block;
        }

        a.collapsed .icon-close {
            display: none;
        }

    }

    @media (max-width: 1024px) {
        .content, .accordion-list {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (max-width: 992px) {
        .img {
            min-height: 400px;
        }
        .content {
            padding-top: 30px;
        }
        .accordion-list {
            padding-bottom: 30px;
        }
    }

    @media (max-width: 575px) {
        .img {
            min-height: 200px;
        }
    }
}
