/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
    padding-top: 40px;
}

.pricing .box {
    padding: 60px 40px;
    box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
    background: #fff;
    height: 100%;
    border-top: 4px solid #fff;
    border-radius: 5px;
}

.pricing h3 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 20px;
    color: $blueDark;
}

.pricing h4 {
    font-size: 48px;
    color: $blueDark;
    font-weight: 400;
    font-family: $styledFont;
    margin-bottom: 25px;
}

.pricing h4 sup {
    font-size: 28px;
}

.pricing h4 span {
    color: $blueLight;
    font-size: 18px;
    display: block;
}

.pricing ul {
    padding: 20px 0;
    list-style: none;
    color: #999;
    text-align: left;
    line-height: 20px;
}

.pricing ul li {
    padding: 10px 0 10px 30px;
    position: relative;
}

.pricing ul i {
    color: #28a745;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 6px;
}

.pricing ul .na {
    color: #ccc;
}

.pricing ul .na i {
    color: #ccc;
}

.pricing ul .na span {
    text-decoration: line-through;
}

.pricing .buy-btn {
    display: inline-block;
    padding: 12px 35px;
    border-radius: 50px;
    color: $blueLight;
    font-size: 16px;
    font-weight: 500;
    font-family: $styledFont;
    // transition: 0.3s;
    border: 1px solid $blueLight;
}

.pricing .buy-btn:hover {
    background: $blueLight;
    color: #fff;
}

.pricing .featured {
    border-top-color: $blueLight;
}

.pricing .featured .buy-btn {
    background: $blueLight;
    color: #fff;
}

.pricing .featured .buy-btn:hover {
    background: #23a3df;
}

@media (max-width: 992px) {
    .pricing .box {
        max-width: 60%;
        margin: 0 auto 30px auto;
    }
}

@media (max-width: 767px) {
    .pricing .box {
        max-width: 80%;
        margin: 0 auto 30px auto;
    }
}

@media (max-width: 420px) {
    .pricing .box {
        max-width: 100%;
        margin: 0 auto 30px auto;
    }
}
