/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
    list-style: none;
    margin-bottom: 20px;
}

.portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    margin: 10px 5px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    color: $darkText;
    // transition: all 0.3s;
    padding: 8px 20px;
    border-radius: 50px;
    font-family: "Poppins", sans-serif;
}

.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
    background: $blueLight;
    color: #fff;
}

.portfolio .portfolio-item {
    margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-img {
    overflow: hidden;
}

.portfolio .portfolio-item .portfolio-img img {
    // transition: all 0.6s;
}

.portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 15px;
    bottom: 0;
    z-index: 3;
    right: 15px;
    // transition: all 0.3s;
    background: rgba(55, 81, 126, 0.8);
    padding: 10px 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
}

.portfolio .portfolio-item .portfolio-info p {
    color: #f9fcfe;
    font-size: 14px;
    margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link, .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #fff;
    // transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover, .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: $blueLight;
}

.portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
}

.portfolio .portfolio-item:hover .portfolio-img img {
    transform: scale(1.15);
}

.portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
}
