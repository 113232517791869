/*--------------------------------------------------------------
# Site Specifics - DwDeveloper
--------------------------------------------------------------*/
.dwdeveloper {

    .at-icon-wrapper {
        background-color: #37517e !important;
    }

    #footer {

        .footer-top {

            .footer-contact {

                h3 {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-family: $styledFont;
                }

            }

        }

    }

}
