/*--------------------------------------------------------------
# Images List
--------------------------------------------------------------*/
section.images-list {
    padding: 10px 0;
    text-align: center;

    a {
        padding-top: 12px;
        padding-bottom: 12px;
    }

}

section.logos-list {

    img {
        // transition: all 0.4s ease-in-out;
        display: inline-block;
        padding: 0;
        -webkit-filter: grayscale(100);
        filter: grayscale(100);
        max-height: 80px;
    }

    img:hover {
        -webkit-filter: none;
        filter: none;
        transform: scale(1.1);
    }

}

section.image-gallery {

    .row {

        .d-flex {
            flex-wrap: wrap;
            justify-content: space-evenly;
        }

    }

}
