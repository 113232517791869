/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background: #f3f5fa;
    min-height: 40px;
    margin-top: 72px;
}

@media (max-width: 992px) {
    .breadcrumbs {
        margin-top: 68px;
    }
}

.breadcrumbs h2 {
    font-size: 28px;
    font-weight: 600;
    color: $blueDark;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
}

.breadcrumbs ol li + li {
    padding-left: 10px;
}

.breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #4668a2;
    content: "/";
}
