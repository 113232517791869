/*--------------------------------------------------------------
# Site Specifics - Zephyros
--------------------------------------------------------------*/
$black60: rgba(0, 0, 0, 0.6);
$black50: rgba(0, 0, 0, 0.5);
$black30: rgba(0, 0, 0, 0.3);
$blueBackground: #080926;
$blueActiveButton: #16165E;
$redBlocks: #6F0D14;
$blueFooter: #0b0d38;

.zephyros {
    background-color: #000000;
    color: #ffffff;

    &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }

    #main, #footer {
        z-index: 2;
    }

    #header {
        background: linear-gradient(to bottom, #152541, rgba(0, 0, 0, 0.2));
        border: none;

        .logo {
            text-align: left;
            width: 100%;

            img {
                max-height: 100px;
                margin: 20px 0;
            }
        }

        .nav-menu {
            ul {
                li {
                    padding: 10px 20px;
                    margin: 10px 10px 0 10px;

                    &.drop-down {
                        &:hover {
                            background-color: $black50;
                            box-shadow: none;
                        }

                        ul {
                            left: 0;
                            border-radius: 0;
                            background-color: $black50;
                            box-shadow: none;

                            li:hover {
                                background-color: $black60;
                            }

                            a {
                                color: #ffffff;

                                &:hover {
                                    color: $blueLightHover;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #footer {
        position: relative;
        z-index: 2;
        background: $blueFooter;

        .logo img {
            max-height: 100px;
        }

        .footer-top {
            background-color: transparent;

            .footer-links ul a {
                color: #ffffff;
            }
        }

    }

    #main {
        margin-top: 172px;
    }

    img.vbox-figlio {
        background: transparent !important;
        padding: 0;
        border: none;
        box-shadow: none;
    }

    .section-title h1, .section-title h2 {
        color: #ffffff;
    }

    .squares-grid {
        &.create-hero {
            .icon-box .icon img {
                max-width: 100%;
                max-height: none;
            }

            h4 {
                text-align: center;
            }
        }

        .icon-box {
            background: linear-gradient(to bottom, rgba(11, 14, 57, 0.5), rgba(0, 0, 0, 0));
            border: 1px solid #fff;
            border-radius: 4px;

            h4, a {
                color: #ffffff;
            }

            :hover {
                cursor: pointer;
            }
        }
    }

    .block-newsletter {
        background: none;
        color: #ffffff;

        h4 {
            color: #ffffff;
        }
    }

    @media (max-width: 991px) {

        section.hero {
            height: auto;
        }

    }

}
